import type { UnwrapRef } from 'vue'
import type { AnyFn } from '~/ts/types/common'

type Options = {
    immediate?: boolean
}

export const debounceFn = (fn: Function, delay: number, options: Options = {}): AnyFn<void> => {
    let immediateCallOccurred = false

    const timer = useTimer((args: any[]) => {
        if (immediateCallOccurred) {
            immediateCallOccurred = false
        } else {
            fn(...args)
        }
    }, delay)

    return (...args: any[]): void => {
        if (options.immediate) {
            if (!immediateCallOccurred) {
                immediateCallOccurred = true

                fn(...args)
            }
        }

        timer.start(args)
    }
}

export const debouncedRef = <T>(initialValue: T, delay: number, options: Options = {}): Ref<UnwrapRef<T>> => {
    const state = ref<T>(initialValue)

    return customRef((track, trigger) => ({
        get(): T {
            track()

            return state.value as T
        },
        set: debounceFn((value: any) => {
            state.value = value

            trigger()
        }, delay, options)
    }))
}
